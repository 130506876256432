<template>
  <div class="">
    <div class="df bg-white head">
      <div class="dfc mr50">
        <el-select
          v-model="minFormdata.videoStatus"
          placeholder="选择合成状态"
        >
          <el-option
            v-for="item in $store.state.statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          > </el-option>
        </el-select>
      </div>
      <div class="dfc mr50">
        <el-input
          v-model="minFormdata.videoName"
          placeholder="请输入视频关键词搜索"
        ></el-input>
      </div>

      <div
        class="df"
        style="width: 60%;position: relative;"
      >

        <button
          class="blue-button"
          @click="getlive"
        >搜索</button>
        <el-button
          type="primary"
          class="hot_pri"
          icon="el-icon-arrow-left"
          @click="video_up_page"
        ></el-button>
        <input
          v-model="videoPage"
          class="page_number"
          @keyup.enter="getlive"
        >
        <el-button
          type="primary"
          class="hot_pri"
          icon="el-icon-arrow-right"
          @click="video_next_page"
        ></el-button>

        <div style="position: absolute;right: -50px;">

          <el-button
            icon="el-icon-delete"
            class="rignt_label"
            @click="clear"
          >清理</el-button>
          <el-button
            icon="el-icon-refresh"
            class="rignt_label"
            @click="getlive"
          >刷新</el-button>
          <el-button
            v-show="!choose"
            icon="el-icon-download"
            class="rignt_label"
            @click="choose = true"
          >选择下载</el-button>
          <el-button
            v-show="choose"
            icon="el-icon-close"
            class="rignt_label"
            @click="choose = false"
          >取消选择</el-button>
          <el-button
            v-show="choose"
            icon="el-icon-check"
            class="rignt_label"
            @click="download"
          >下载</el-button>
        </div>
      </div>
    </div>

    <div
      class="pl20"
      style="background-color: black;"
    >
      <el-checkbox-group
        v-model="checkList"
        style="padding-top: 15px;"
      >
        <div
          v-if="videoList.length > 0"
          class="df f-w box"
        >
          <div
            v-for="(item, idx) in videoList"
            :key="idx"
            class="imgBox"
            @click="getItem(item)"
          >
            <img
              :src="item.video_image"
              object-fit="cover"
              alt=""
              style="background-color: #243748;border-radius: 10px;"
            />
            <div class="desc textOver">
              {{ item.title }}
              <div class="time">{{ item.create_time }}</div>
            </div>
            <div
              v-show="!choose"
              class="tools"
              style="color: #ff5733"
              @click.stop="delItem(item)"
            >
              <i class="el-icon-delete"></i>
            </div>
            <div
              v-show="choose"
              class="tools"
              style="color: #ff5733"
              @click.stop
            >
              <el-checkbox
                v-show="choose"
                :value="item.id"
                :label="item.id"
              ></el-checkbox>
            </div>
            <div
              class="status"
              :style="item.statusa | getColor"
            >
              <div class="sta">
                {{ item.statusa | getStatus }}
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <myempty text="视频"></myempty>
        </div>
      </el-checkbox-group>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="38%"
    >
      <el-input
        v-model="ipt"
        placeholder="请输入直播方案名称"
      ></el-input>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false;ipt = '';">取 消</el-button>
        <el-button
          type="primary"
          @click="add"
        >提 交</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="activeInfo.title"
      :visible.sync="showVideo"
      width="28%"
      :destroy-on-close="true"
    >
      <video
        ref="v1"
        :src="activeInfo.url"
        class="vd"
        controls
      ></video>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          style="background-color: #ffffff00;color: #ffffff; border-radius: 10px;"
          @click="showVideo = false"
        >取 消</el-button>
        <el-button
          class="blue-button"
          @click="showVideo = false"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getlive, dellive, editlive, createlive, get_live_video } from "@/api/live.js";
import { getVideo, del_video } from "@/api/video.js";
import { downloadVideo } from "@/utils/tools";
import myempty from "@/components/myempty.vue";
import videoList from "@/views/live/components/video/videoList";
import { get_video_list } from "@/api/live";
var timer;
export default {
  components: {
    myempty,
  },
  filters: {
    getStatus: (val) => {
      if (!val) return "";
      return val == "1" ? "合成中" : val == "3" ? "已完成" : "合成失败";
    },
    getColor: (val) => {
      if (!val) return "";
      return val == "1" ? "background: #0052d9;" : val == "3" ? "background:#43CF7C;" : "background:#FF5733;";
    },
  },
  data () {
    return {
      visible: false,
      dialogVisible: false,
      innerVisible: false,
      choose: false,
      showVideo: false,
      minFormdata: {
        videoStatus: "",
        videoName: "",
      },
      ipt: "",
      title: "添加直播方案",
      player: null, // 用来存储当前 video
      inputLive: "",
      liveAddr: "",
      roomId: "", //直播房间号
      activeInfo: {},
      activeI: {},
      chooseRoom: "",
      liveInfo: {
        livelist: [],
        keywordList: [],
      },
      downloadList: [],
      value: "",
      isPlay: false,
      videoList: [],
      checkList: [],
      videoPage: 1,
      pageNum: 1,
      videoPerPage: 14,
      pageNow: "shortVideo"
    };
  },
  mounted () {
    this.getlive();
    timer = setInterval(() => {
      this.getlive();
    }, 600000);
  },
  destroyed () {
    clearTimeout(timer);
  },
  methods: {
    getItem (item) {
      if (item.statusa == "3") {
        this.activeInfo = item;
        this.showVideo = true;
      }
    },
    clear () {
      this.minFormdata = {
        videoStatus: "",
        videoName: "",
      };
      this.getlive();
    },
    download () {
      if (this.checkList.length > 0) {
        this.checkList.map((i) => {
          let obj = this.videoList.find((j) => j.id === i);
          if (obj.url) {
            downloadVideo(obj.url);
          }
        });
      } else {
        this.$message.Warning("请选择文件下载！");
      }
    },
    add () {
      if (!this.ipt) {
        this.$message.error("请输入直播方案名称");
        return;
      }

      switch (this.type) {
        case "rename":
          editlive({
            live_id: this.activeI.id,
            title: this.ipt,
          }).then((res) => {
            if (res.code == "200") {
              this.dialogVisible = false;
              this.$emit("add", this.activeI.id);
            } else {
              this.$message.error(res.msg);
            }
          });
          break;
        default:
          createlive({
            title: this.ipt,
          }).then((res) => {
            if (res.code == "200") {
              this.dialogVisible = false;
              this.$emit("add", res.data.live_id);
            } else {
              this.$message.error(res.msg);
            }
          });
          break;
      }
      this.ipt = "";
    },
    getlive2 () {
      const loading = this.$loading({
        lock: true,
        text: "获取数据中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.videoList.length == 0 && this.videoPage != 1) {
        if (this.videoPage != "") {
          this.videoPage = 1
          this.$message.error("页码不存在已回到第一页");
        } else {
          this.videoPage = 1
          this.$message.error("页码为空已回到第一页");
        }
      }
      getVideo({
        page: this.videoPage,
        item: this.videoPerPage,
        type_s: 1,
        statusa: this.minFormdata.videoStatus || "",
        so: this.minFormdata.videoName || "",
      }).then((res) => {
        setTimeout(() => {
          loading.close();
          if (res.code == "200") {
            this.videoList = res.data.list;
            this.pageNum = res.data.page_num;
            console.log(this.videoList)
          } else {
            this.$message.error(res.msg);
          }
        }, 200);
      });
    },

    getlive () {
      const loading = this.$loading({
        lock: true,
        text: "获取数据中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.videoPage == "") {
        this.$message.error("页码为空已回到第一页");
        this.videoPage = this.videoPage = 1
        get_video_list({
          page: this.videoPage,
          item: this.videoPerPage,
          type_s: 1,
          statusa: this.minFormdata.videoStatus || "",
          so: this.minFormdata.videoName || "",
        }).then((res) => {
          setTimeout(() => {
            loading.close();
            if (res.code == "200") {
              this.videoList = res.data.list;
            } else {
              this.$message.error(res.msg);
            }
          }, 200);
        });
      } else if (this.videoPage > this.pageNum && this.videoPage != 1) {
        this.$message.error("页码不存在已回到第一页,共" + this.pageNum + "页");
        this.videoPage = this.videoPage = 1
        get_video_list({
          page: this.videoPage,
          item: this.videoPerPage,
          type_s: 1,
          statusa: this.minFormdata.videoStatus || "",
          so: this.minFormdata.videoName || "",
        }).then((res) => {
          setTimeout(() => {
            loading.close();
            if (res.code == "200") {
              this.videoList = res.data.list;
            } else {
              this.$message.error(res.msg);
            }
          }, 200);
        });
      } else {
        this.videoPage = parseInt(this.videoPage)
        get_video_list({
          page: this.videoPage,
          item: this.videoPerPage,
          type_s: 1,
          statusa: this.minFormdata.videoStatus || "",
          so: this.minFormdata.videoName || "",
        }).then((res) => {
          setTimeout(() => {
            loading.close();
            if (res.code == "200") {
              this.videoList = res.data.list;
              this.pageNum = res.data.page_num;
              if (this.videoPage == 1 && this.pageNow == "shortVideo") {
                this.$message.success("加载完成共：" + res.data.page_num + "页");
              }
            } else {
              this.$message.error(res.msg);
            }
          }, 200);
        });
      }
    },

    getVideoList () {
      return this.videoList
    },
    video_next_page () {
      if (this.videoPage <= this.pageNum) {
        this.videoPage += 1
        this.getlive()
      } else {
        this.$message.error("已经是最后一页！！！！");
      }
    },
    video_up_page () {
      if (this.videoPage > 1) {
        this.videoPage -= 1
        this.getlive()
      }
    },
    delItem (i) {
      this.$confirm("此操作将永久删除该视频, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((_) => {
          del_video({
            video_id: i.id,
          }).then((res) => {
            if (res.code == "200") {
              this.$message.success(res.msg);
              this.getlive();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((_) => { });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input__inner {
  border: 0;
  color: #939dae;
  background: rgba(27, 36, 49, 1);
}
.hot_pri {
  width: 4rem;
  height: 2.5rem;
  position: relative;
  padding: 12px 22px;
  display: flex;
  float: left;
  margin-left: 10px;
  opacity: 1;
  border-radius: 0.625rem;
  ::v-deep {
    .el-button--primary {
      background: #3f3f3f00 !important;
    }
  }
}
.page_number {
  border: none;
  width: 50px;
  text-align: center;
  border-radius: 0.625rem;
  margin-left: 10px;
  outline: none;
}
/deep/.el-textarea__inner {
  background: #f1f4f9 !important;
}
/deep/.el-checkbox__inner {
  width: 20px;
  height: 20px;
  border: 1px solid #015ce6 !important;
  margin-left: 3px;
}
/deep/.el-checkbox__inner::after {
  height: 12px;
  left: 7px;
}
/deep/.el-checkbox-group {
  font-size: 15px !important;
}
/deep/.el-dialog__header {
  padding: 20px 20px 10px;
  text-align: left;
  font-weight: bold;
}

/deep/.el-dialog {
  border-radius: 8px;
  background-color: rgba(45, 50, 64, 1);
}

/deep/.el-dialog__title {
  color: #ffffff;
}
.rignt_label {
  background-color: #ffffff00;
  border-style: none;
  color: #ffffff;
  font-size: 14px;
}
.blue-button {
  border-radius: 5px;
  border-style: none;
  width: 80px;
  height: 40px;
  background: linear-gradient(
    90deg,
    rgba(54, 243, 170, 1) 0%,
    rgba(4, 221, 181, 1) 100%
  );
  border-radius: 10px;
}
.title {
  text-align: left;
  font-weight: bold;
  font-size: 16px;
}

.info {
  padding-top: 5px;
  padding-bottom: 5px;

  /deep/.el-pagination {
    padding: 5px 5px 0 !important;
  }
}

.status {
  position: relative;
  top: -395px;
  left: 5px;
  width: 62px;
  height: 20px;
  opacity: 0.9;
  border-radius: 3px;

  background-size: contain;
  .sta {
    font-size: 12px;
    text-align: center;
    padding-top: 5px;
    line-height: 14px;
    // margin-left: -3px;
    // font-weight: 600;
    color: #ffffff;
  }
}
</style>
