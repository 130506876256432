<template>
  <div class="bg">
    <div style="margin-top: 10vh; height: 200px;">
      <el-empty :image="src" :description="'暂无' + text" style="height: 65px"></el-empty>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    //
    text: {
      type: String,
      default: "内容",
    },
  },
  data() {
    return {
      src: require("@/assets/img/empty.png"),
      // desc: "暂无" + this.text,
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
/deep/.el-empty__description p {
  margin: -20px;
  font-size: 15px !important;
  color: #015ce6 !important;
}
</style>
